import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'sw-ui';
import DatePicker from 'react-datetime';

import Checkbox from '../../../../components/checkbox';
import Input from '../../../../components/input';
import { FlatButton } from '../../../../components/button';
import { Currency } from './components/Currency';
import SelectProject from '../selectProject';
import SelectDepartment from '../selectDepartment';
import AnalyticsRows from '../analyticsRows';
import { VatBlock } from './compoments/VatBlock';
import { VatDialog } from './compoments/VatDialog';
import { VatErrorDialog } from './compoments/VatErrorDialog';
import { MealFields } from './components/MealFields';

import { findDepartments, preventKeyNotNumber } from '../../../../bi/utils/trip';
import { isMandatory } from '../../../../bi/utils/account';
import {
  dateParseZone,
  dateUtcFormat,
  timeWithoutMoment,
  momentObject,
  formatDate,
  isValidMomentObject,
  diffDays,
} from '../../../../bi/utils/formatDate';
import {
  applyAnalytics, removeAnalytics, validateSingleAnalytics, validateAnalytics,
} from '../../../../bi/utils/customAnalytics';
import { prepareMealForSave } from '../../../../bi/utils/hotels';

import {
  DEFAULTDATE, DEFAULTTIME, LOCALERU, FULLTIME, FORMATDATE, DATE,
} from '../../../../constants/time';
import {
  HOTEL_PROVIDER_VALUE, HOTEL_PROVIDER_IS_AUTO_CALC_VAT, CURRENCY_VALUES,
} from '../../../../bi/constants/hotel';
import {
  FIELDS, PRICEFIELDSSHORT, PRICELABELS, TRIPSLABELS,
} from '../../../../bi/constants/trips';
import COMPONENTS from '../../../../bi/constants/components';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';
import {
  VAT_VALUE_BY_VAT_BLOCK, VATVALUE, MEAL_LIST_TYPE,
} from '../../../../bi/constants/hotels';

import styles from '../../styles/form.module.scss';

const LABELS = {
  CANCELLATION: 'Условия отмены',
  PERIOD: 'Период',
  CANCELLATION_DATE: 'Дата снятия брони без потерь',
  VOUCHER_INFO: 'Данные ваучера',
  CHECKIN_DATE: 'Дата заезда',
  CHECKOUT_DATE: 'Дата выезда',
  PROVIDER: 'Провайдер',
  EMPLOYEE: 'Сотрудник',
  RESERVATION_DATE: 'Дата бронирования',
  GUEST_COUNT: 'Количество гостей',
  STARS: 'Количество звезд',
  NAME: 'Наименование',
  ADDRESS: 'Адрес',
  CITY: 'Город',
  PHONE: 'Телефон',
  CHECKIN_TIME: 'Время заселения',
  CHECKOUT_TIME: 'Время выселения',
  ROOM_CATEGORY: 'Категория комнаты',
  BREAKFAST: 'Питание',
  FREE_CANCELLATION_NOT_POSSIBLE: 'Бесплатная отмена бронирования невозможн',
};

const BUTTONS = {
  ADD: '+ добавить условие по штрафам',
  REMOVE: '- удалить условие',
};

const CANCELLATION_PENALTIES = { FROM: 'From' };

class PartHotel extends Component {
  static propTypes = {
    hotel: PropTypes.object,
    tripService: PropTypes.object,
    accountId: PropTypes.number.isRequired,
    onConfirm: PropTypes.func,
    projects: PropTypes.arrayOf(PropTypes.object),
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    mandatoryProject: PropTypes.array.isRequired,
    isTripPaidByPersonalFunds: PropTypes.bool,
  };

  static defaultProps = {
    hotel: {},
    tripService: {},
    projects: [],
    onConfirm: () => {},
    analytics: [],
    tripAnalyticsValueIds: [],
    setTripAnalytics: () => {},
    isTripPaidByPersonalFunds: false,
  };

  constructor(props) {
    super(props);

    const hotel = props.hotel;
    const {
      Employees,
      CompanyId,
      ProjectId,
      DepartmentId,
      OrderTripItemId,
      ServiceType,
      ReportAmount,
      UserAnalytics = [],
    } = hotel;
    const currentEmployeeId = hotel && Employees && Employees[0] && Employees[0].Id;
    const newMandatory = isMandatory(this.props.mandatoryProject, CompanyId);
    const jsonData = JSON.parse(hotel.JsonData);
    const { PriceDetails, Room: { Meal: { Category } } } = jsonData;
    const { list, label } = MEAL_LIST_TYPE.find(({ value }) => value === Category);

    const state = {
      OrderTripItemId,
      ServiceType,
      Status: 3,
      JsonData: jsonData,
      PriceDetails,
      InternalVat: {
        VatReady: false,
        VatInfo: null,
      },
      Employees,
      CompanyId: CompanyId || 0,
      ProjectId: ProjectId || 0,
      DepartmentId: DepartmentId || 0,
      EmployeeId: currentEmployeeId,
      mandatoryProject: newMandatory,
      departments: [],

      UserAnalytics,

      ReportAmount,
      CurrencyData: {
        Base: 0,
        Commission: 0,
        Total: 0,
        CurrencyName: CURRENCY_VALUES.USD,
        CurrencyValue: '',
        CurrencyMultiplierPercent: '',
        CurrencyValueWithMultiplier: '',
        DateUTC: '',
        Multiplier: '',
        MFInCurrency: 0,
        MF: 0,
        Inclusive: 0,
        RequiredTotalInRub: PriceDetails.Total,
      },
      Meal: list,
      MealLabel: label,
      showTooltipCalculate: false,

      validation: {
        ReservationNumber: '',
        ProjectId: '',
        Base: '',
        Commission: '',
        analytics: {},
        CheckinDate: '',
        CheckoutDate: '',
        Hotel: {
          CheckoutTime: '',
          CheckinTime: '',
        },
        Room: { Category: '' },
        Total: '',
        Penalty: { Base: '' },
        CurrencyInfo: {
          CurrencyMFInCurrency: '',
          CurrencyInclusive: '',
          CurrencyBase: '',
          CurrencyCommission: '',
        },
        EarlyCheckIn: '',
        LateCheckOut: '',
      },
      showVatDialog: false,
      showNotificationDialog: false,
      internalVatDialogValue: {
        RackRate: 0,
        Rate: '',
        Amount: 0,
      },
    };

    const { CancellationPenalties = [] } = state.JsonData.Room;

    state.JsonData.CheckinDate = momentObject(formatDate(state.JsonData.CheckinDate, DATE));
    state.JsonData.CheckoutDate = momentObject(formatDate(state.JsonData.CheckoutDate, DATE));
    state.CancellationPenalties = CancellationPenalties.map((item) => {
      const penalty = item;
      penalty.From = momentObject(timeWithoutMoment(penalty.From, FULLTIME));

      return penalty;
    }) || [{}];
    state.JsonData.Room.FreeCancellation = state.JsonData.Room.FreeCancellation
      ? state.JsonData.Room.FreeCancellation
      : null;

    state.showCancellation = !!state.JsonData.Room.FreeCancellation;

    this.providerNameOptions = props.tripService.getHotelProviderName();

    this.state = state;
  }

  componentDidMount() {
    const { EmployeeId, CompanyId } = this.state;

    this.props.tripService.getEmployeeDataByID(EmployeeId)
      .then(((res) => this.setState({ departments: findDepartments(res, CompanyId) })))
      .catch(() => this.setState({ departments: [] }));

    this.handleSetNightsCount();
  }

  handlerChangePriceInput = (e, field, value) => this.setState({
    PriceDetails: {
      ...this.state.PriceDetails,
      [field]: value,
    },
  });

  handleConfirm = () => {
    const {
      OrderTripItemId,
      ServiceType,
      Status,
      JsonData,
      PriceDetails,
      Employees,
      CompanyId,
      ProjectId,
      DepartmentId,
      UserAnalytics = [],
      CancellationPenalties,
      InternalVat,
      CurrencyData: {
        Base: BaseInCurrency,
        Commission,
        Total: TotalInCurrency,
        CurrencyName,
        CurrencyValue,
        DateUTC,
        MFInCurrency,
        MF,
        Multiplier,
        CurrencyMultiplierPercent,
        CurrencyValueWithMultiplier,
        Inclusive,
      },
      Meal,
      ReportAmount,
    } = this.state;
    const { isValid, validation } = this.validationSubmit({
      JsonData,
      PriceDetails,
      InternalVat,
    });

    if (isValid) {
      JsonData.EmployeeIds = Employees.map((item) => item.Id);
      JsonData.PriceDetails = {
        ...PriceDetails,
        Commission: parseFloat(PriceDetails.Commission) || 0,
      };

      JsonData.CheckinDate = formatDate(JsonData.CheckinDate, FULLTIME);
      JsonData.CheckoutDate = formatDate(JsonData.CheckoutDate, FULLTIME);

      JsonData.Room.CancellationPenalties = CancellationPenalties.map(({
        From, Total, Base, Additional,
      }) => ({
        From: formatDate(From, FULLTIME),
        Base,
        Total: parseFloat(Total),
        Additional,
      }));

      const roomCancel = JsonData.Room.FreeCancellation;
      const isValidRoomCancel = roomCancel ? timeWithoutMoment(roomCancel, FULLTIME) : null;
      JsonData.Room.FreeCancellation = roomCancel ? isValidRoomCancel : roomCancel;

      const internalVat = InternalVat.VatReady
        ? {
          VatReady: InternalVat.VatReady,
          VatInfo: {
            HasVat: InternalVat.VatInfo.HasVat,
            RackRate: InternalVat.VatInfo.RackRate || 0,
            VatDetails: InternalVat.VatInfo.HasVat
              ? InternalVat.VatInfo.VatDetails
              : [],
          },
        }
        : {
          VatReady: false,
          VatInfo: null,
        };

      const currencyData = JsonData.ProviderName === HOTEL_PROVIDER_VALUE.expedia
        ? {
          BaseInCurrency,
          TotalInCurrency,
          CommissionInCurrency: Commission,
          MF,
          MFInCurrency,
          CurrencyName,
          CurrencyValue,
          CurrencyMultiplier: Multiplier,
          CurrencyDateUTC: DateUTC,
          CurrencyMultiplierPercent,
          CurrencyValueWithMultiplier,
          Inclusive,
        }
        : null;

      const item = {
        OrderTripItemId,
        TripId: 0,
        Status,
        ServiceType,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),
        JsonData: JSON.stringify(JsonData),
        UserAnalytics,
        InternalVat: internalVat,
        CurrencyData: currencyData,
        Meal: prepareMealForSave(Meal),
        ReportAmount,
      };

      this.props.onConfirm(item);
    } else {
      this.setState({ validation: { ...validation } });
    }
  };

  getPenaltyTotal = (CancellationPenalties) => CancellationPenalties
    .map(({ Total }) => (this.props.tripService.hotelTripFieldValidation('Total', Total)));

  getPenaltyBase = (CancellationPenalties, JsonData) => CancellationPenalties
    .map(({ Additional, Base }) => ((Additional && !Base && !JsonData.IsCustom) || (typeof Base !== 'number')
      ? (this.props.tripService.hotelTripFieldValidation(FIELDS.BASE_PENALTY_FULL, Base))
      : ''));

  getValid = (valid, i) => {
    if (!valid || !valid.length) return null;

    return valid[i];
  };

  validationSubmit = ({
    JsonData, PriceDetails, InternalVat,
  }) => {
    const {
      tripService,
      tripAnalyticsValueIds: tripAnalyticsValues,
      analytics,
      projects,
      isTripPaidByPersonalFunds,
    } = this.props;
    const {
      UserAnalytics: serviceAnalyticsValues,
      CancellationPenalties,
      mandatoryProject,
      ProjectId,
      CurrencyData,
    } = this.state;
    const { ProviderName } = JsonData;

    const projectMassage = projects.length
      ? tripService.hotelTripFieldValidation(FIELDS.PROJECTID, ProjectId)
      : ERRORSFORALL.NO_PROJECTS;

    const projectId = mandatoryProject && !isTripPaidByPersonalFunds ? projectMassage : '';
    const reservedNum = tripService.hotelTripFieldValidation('ReservationNumber', JsonData.ReservationNumber);
    const price = tripService.hotelTripFieldValidation('Base', PriceDetails.Base);
    const commissionPrice = tripService.hotelTripFieldValidation('Commission', PriceDetails.Commission);
    const checkinDate = tripService.hotelTripFieldValidation('CheckinDate', JsonData.CheckinDate);
    const checkoutDate = tripService.hotelTripFieldValidation('CheckoutDate', JsonData.CheckoutDate);
    const checkinTime = tripService.hotelTripFieldValidation('Hotel.CheckinTime', JsonData.Hotel.CheckinTime);
    const checkoutTime = tripService.hotelTripFieldValidation('Hotel.CheckoutTime', JsonData.Hotel.CheckoutTime);
    const category = tripService.hotelTripFieldValidation('Room.Category', JsonData.Room.Category);
    const penaltyTotal = CancellationPenalties ? this.getPenaltyTotal(CancellationPenalties) : [];
    const penaltyBase = CancellationPenalties ? this.getPenaltyBase(CancellationPenalties, JsonData) : [];
    const currencyMarketingFee = ProviderName === HOTEL_PROVIDER_VALUE.expedia
      ? tripService.hotelTripFieldValidation(FIELDS.MF_IN_CURRENCY, CurrencyData.MFInCurrency)
      : '';
    const currencyInclusive = ProviderName === HOTEL_PROVIDER_VALUE.expedia
      ? tripService.hotelTripFieldValidation(FIELDS.CURRENCY_INCLUSIVE, CurrencyData.Inclusive)
      : '';
    const currencyBase = ProviderName === HOTEL_PROVIDER_VALUE.expedia
      ? tripService.hotelTripFieldValidation(FIELDS.CURRENCY_BASE, CurrencyData.Base)
      : '';
    const currencyCommission = ProviderName === HOTEL_PROVIDER_VALUE.expedia
      ? tripService.hotelTripFieldValidation(FIELDS.CURRENCY_COMMISSION, CurrencyData.Base)
      : '';
    const earlyCheckIn = JsonData.CustomCheckInDate
      && tripService.hotelTripFieldValidation(FIELDS.EARLYCHECKIN, PriceDetails.EarlyCheckIn);
    const lateCheckOut = JsonData.CustomCheckOutDate
      && tripService.hotelTripFieldValidation(FIELDS.LATECHECKOUT, PriceDetails.LateCheckOut);

    const userAnalytics = validateAnalytics(tripAnalyticsValues, serviceAnalyticsValues, analytics);
    const hasUnsetRequiredAnalytics =
      !isTripPaidByPersonalFunds && Object.keys(userAnalytics).some((key) => !!userAnalytics[key]);

    const total = penaltyTotal.filter((item) => item !== '').length !== 0;
    const base = penaltyBase.filter((item) => item !== '').length !== 0;

    const validInternalVat = InternalVat.VatReady === null
      || (InternalVat.VatReady
        && InternalVat.VatInfo.HasVat
        && InternalVat.VatInfo.VatDetails[0].Rate === 20
        && InternalVat.VatInfo.VatDetails[0].Amount === 0
      );

    const isValid = reservedNum
      || price
      || commissionPrice
      || hasUnsetRequiredAnalytics
      || checkinDate
      || checkoutDate
      || checkinTime
      || checkoutTime
      || category
      || total
      || projectId
      || base
      || validInternalVat
      || currencyMarketingFee
      || currencyInclusive
      || currencyBase
      || currencyCommission
      || earlyCheckIn
      || lateCheckOut;

    if (isValid) {
      return {
        isValid: false,
        validation: {
          ProjectId: projectId,
          CheckinDate: checkinDate,
          CheckoutDate: checkoutDate,
          Hotel: {
            CheckoutTime: checkoutTime,
            CheckinTime: checkinTime,
          },
          Room: { Category: category },
          Total: penaltyTotal,
          Penalty: { Base: penaltyBase },
          ReservationNumber: reservedNum,
          Base: price,
          Commission: commissionPrice,
          analytics: userAnalytics,
          CurrencyInfo: {
            CurrencyMFInCurrency: currencyMarketingFee,
            CurrencyInclusive: currencyInclusive,
            CurrencyBase: currencyBase,
            CurrencyCommission: currencyCommission,
          },
          EarlyCheckIn: earlyCheckIn,
          LateCheckOut: lateCheckOut,
        },
      };
    }

    return { isValid: true };
  };

  validationInput = (e, field) => {
    const part = field.split('.');

    if (part.length === 1) {
      const result = this.props.tripService.hotelTripFieldValidation(field, this.state.JsonData[field]);

      return this.setState({
        validation: {
          ...this.state.validation,
          [field]: result,
        },
      });
    }

    const result = this.props.tripService.hotelTripFieldValidation(field, this.state.JsonData[part[0]][part[1]]);

    return this.setState({
      validation: {
        ...this.state.validation,
        [part[0]]: {
          ...this.state.validation[part[0]],
          [part[1]]: result,
        },
      },
    });
  };

  validationPrice = (e, field) => {
    const result = this.props.tripService.hotelTripFieldValidation(field, this.state.PriceDetails[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validationCurrency = (e, field) => {
    const { tripService } = this.props;
    const { CurrencyData, validation } = this.state;

    const result = tripService.hotelTripFieldValidation(`Currency${field}`, CurrencyData[field]);

    this.setState({
      validation: {
        ...validation,
        CurrencyInfo: {
          ...validation.CurrencyInfo,
          [`Currency${field}`]: result,
        },
      },
    });
  };

  handleSelectProject = ({ id }) => {
    const { tripService } = this.props;
    const { mandatoryProject } = this.state;
    const result = mandatoryProject ? tripService.hotelTripFieldValidation(FIELDS.PROJECTID, id) : '';

    this.setState({
      ProjectId: id,
      validation: {
        ...this.state.validation,
        ProjectId: result,
      },
    });
  };

  handleSelectDepartment = ({ id }) => this.setState({ DepartmentId: id });

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [], validation } = this.state;

    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    const updatedServiceAnalytics = ApplyToTrip
      ? {}
      : { UserAnalytics: updatedAnalyticsValues };

    this.setState({
      ...updatedServiceAnalytics,
      validation: {
        ...validation,
        analytics: {
          ...validation.analytics,
          ...analyticsValidation,
        },
      },
    });
  };

  validateDate = (field) => {
    const result = this.props.tripService.hotelTripFieldValidation(field, this.state.JsonData[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  handleChangeJsonInput = (e, field, value) => {
    const { JsonData } = this.state;
    const part = field.split('.');

    if (part.length === 1) {
      JsonData[field] = value;
    } else {
      JsonData[part[0]][part[1]] = value;
    }

    this.setState({ JsonData }, () => {
      if (field === FIELDS.CHECKIN_DATE || field === FIELDS.CHECKOUT_DATE || field === FIELDS.NIGHTS_PRICE) {
        this.handleSetNightsCount();
      }
    });
  };

  handleSetNightsCount = () => {
    const { JsonData: { CheckinDate, CheckoutDate } } = this.state;

    if (isValidMomentObject(CheckinDate) && isValidMomentObject(CheckoutDate)) {
      const diff = diffDays(momentObject(CheckinDate), momentObject(CheckoutDate));
      this.handleChangeJsonInput(null, FIELDS.NIGHTS_COUNT, diff);
      this.handleCalculateBase();
    }
  };

  handleCalculateBase = () => {
    const { JsonData: { NightsCount, NightsPrice } } = this.state;

    if (NightsCount > 0 && NightsPrice > 0) {
      const price = parseFloat((NightsCount * NightsPrice).toFixed(2));

      this.setState({
        PriceDetails: {
          ...this.state.JsonData.PriceDetails,
          Base: price,
        },
      });
    }
  };

  handleChangeCheckbox = (e, field, value) => this.setState({
    JsonData: {
      ...this.state.JsonData,
      [field]: value,
    },
  });

  handleAddCancellation = () => {
    const newCancellationPenalties = [...this.state.CancellationPenalties];

    newCancellationPenalties.push({ From: null, Total: '' });

    this.setState({ CancellationPenalties: newCancellationPenalties });
  };

  handleRemoveCancellation = (i) => {
    const newCancellationPenalties = [...this.state.CancellationPenalties];

    const changeItems = (index) => [
      ...newCancellationPenalties.slice(0, index),
      ...newCancellationPenalties.slice(index + 1),
    ];

    this.setState({ CancellationPenalties: changeItems(i) });
  };

  handleChangePenaltyInput = (field, value, i) => {
    const { CancellationPenalties } = this.state;

    const newCancellationPenalties = CancellationPenalties.map((item, index) => {
      if (index === i) {
        const penalty = item;
        penalty[field] = value;

        return penalty;
      }

      return item;
    });

    this.setState({ CancellationPenalties: newCancellationPenalties });
  };

  handleChangeECLCCheckbox = (e, field, value) => {
    const {
      JsonData,
      JsonData: { PriceDetails },
    } = this.state;

    const priceField = field === FIELDS.CUSTOMCHECKINDATE ? FIELDS.EARLYCHECKIN : FIELDS.LATECHECKOUT;

    this.setState({
      JsonData: {
        ...JsonData,
        [field]: value,
        PriceDetails: {
          ...PriceDetails,
          [priceField]: value ? PriceDetails[priceField] : 0,
        },
      },
      PriceDetails: {
        ...PriceDetails,
        [priceField]: value ? PriceDetails[priceField] : 0,
      },
    });
  };

  handleChangePriceDetailsInput = (e, field, value) => this.setState({
    PriceDetails: {
      ...this.state.PriceDetails,
      [field]: typeof value === 'number' ? parseFloat(value.toFixed(2)) : value,
    },
  });

  handleChangeAdditionalInfo = (field, value) => {
    this.setState({
      JsonData: {
        ...this.state.JsonData,
        [field]: value,
      },
    });
  };

  handleChangeVatReady = () => {
    this.setState({
      InternalVat: {
        VatReady: false,
        VatInfo: null,
      },
    });
  };

  handleAddVat = () => this.setState({ showVatDialog: true });

  handleChangeVatDetailsRadio = (field, value) => {
    this.setState({
      internalVatDialogValue: {
        ...this.state.internalVatDialogValue,
        Amount: ((value !== VAT_VALUE_BY_VAT_BLOCK.VAT20
          || value !== VAT_VALUE_BY_VAT_BLOCK.VAT7
          || value !== VAT_VALUE_BY_VAT_BLOCK.VAT5)
        && this.state.internalVatDialogValue.Amount)
          ? 0
          : this.state.internalVatDialogValue.Amount,
        RackRate: ((value !== VAT_VALUE_BY_VAT_BLOCK.VAT20
          || value !== VAT_VALUE_BY_VAT_BLOCK.VAT7
          || value !== VAT_VALUE_BY_VAT_BLOCK.VAT5)
        && this.state.internalVatDialogValue.RackRate)
          ? 0
          : this.state.internalVatDialogValue.RackRate,
        [field]: value,
      },
    });
  };

  handleChangeVatDetailsFieldInput = (e, field, value) => {
    this.setState({
      internalVatDialogValue: {
        ...this.state.internalVatDialogValue,
        [field]: value,
      },
    });
  };

  closeVatDialog = () => {
    this.setState({
      showVatDialog: false,
      internalVatDialogValue: {
        Amount: 0,
        RackRate: 0,
        Rate: '',
      },
    });
  };

  handleConfirmVatDialog = async () => {
    const { tripService } = this.props;
    const {
      JsonData: { ProviderName },
      PriceDetails: { Base },
      internalVatDialogValue: {
        Rate,
        RackRate,
        Amount,
      },
    } = this.state;

    if (Rate === VATVALUE.WITHOUT) {
      return this.setState({
        showVatDialog: false,
        InternalVat: {
          VatReady: true,
          VatInfo: {
            HasVat: false,
            RackRate,
            VatDetails: [],
          },
        },
        internalVatDialogValue: {
          Amount: 0,
          RackRate: 0,
          Rate: '',
        },
      });
    }

    // TODO перенести в сервис
    const isAutoCalc = HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(ProviderName);
    let amount = Amount;

    try {
      if (isAutoCalc) {
        amount = await tripService.getVatAmount({
          Rate: Number(Rate),
          Amount: Base,
        });
      }

      return this.setState({
        showVatDialog: false,
        InternalVat: {
          VatReady: true,
          VatInfo: {
            HasVat: true,
            RackRate,
            VatDetails: [
              {
                Rate: Number(Rate),
                Amount: isAutoCalc ? amount.Vat : Amount,
              },
            ],
          },
        },
        internalVatDialogValue: {
          Amount: 0,
          RackRate: 0,
          Rate: '',
        },
      });
    } catch (e) {
      return this.setState({ showNotificationDialog: true });
    }
  };

  handleChangeCurrency = (field, value) => {
    this.setState(({
      CurrencyData: {
        ...this.state.CurrencyData,
        [field]: value,
      },
    }));
  };

  handleCalculateCurrency = async () => {
    const { tripService, accountId } = this.props;
    const {
      JsonData: {
        ProviderName, Hotel: { CountryCode }, RateId,
      },
      CurrencyData,
      PriceDetails,
    } = this.state;
    const {
      MFInCurrency, Inclusive, RequiredTotalInRub,
    } = CurrencyData;

    const data = {
      AccountId: accountId,
      Provider: ProviderName,
      ProviderPrice: Inclusive,
      MF: MFInCurrency,
      CountryCode,
      RateId,
      RequiredTotalInRub: RequiredTotalInRub || 0,
    };

    const res = await tripService.getPricesBookingMarkup(data);

    if (res) {
      const {
        Price, Currency: CurrencyInf, PriceInCurrency,
      } = res;
      const {
        Name, Value, MultiplierPercent, ValueWithMultiplier, DateUTC, Multiplier,
      } = CurrencyInf;
      const {
        Base: BaseCurrency, Commission: CommissionCurrency, Total, MF: MFCurrency, ProviderPrice,
      } = PriceInCurrency;
      const {
        Base, Commission, MF,
      } = Price;

      this.setState({
        PriceDetails: {
          ...PriceDetails,
          Base,
          Commission,
        },
        CurrencyData: {
          ...CurrencyData,
          CurrencyName: Name,
          CurrencyValue: Value,
          CurrencyMultiplierPercent: MultiplierPercent,
          CurrencyValueWithMultiplier: ValueWithMultiplier,
          DateUTC,
          Multiplier,
          Base: BaseCurrency,
          Commission: CommissionCurrency,
          Total,
          MFInCurrency: MFCurrency,
          Inclusive: ProviderPrice,
          MF,
        },
      });
    }
  };

  handleChangeState = (field, value) => this.setState({ [field]: value });

  handleChangeReportAmount = (field, value) => this.setState({ [field]: typeof value === 'number' ? parseFloat(value.toFixed(2)) : value });

  closeNotificationVatDialog = () => {
    this.setState({ showVatNotificationDialog: false });
  };

  renderVatDialog = () => {
    const {
      showVatDialog, JsonData: { ProviderName }, internalVatDialogValue,
    } = this.state;

    if (!showVatDialog) return null;

    return (
      <VatDialog
        internalVat={ internalVatDialogValue }
        providerName={ ProviderName }
        onChangeRadio={ this.handleChangeVatDetailsRadio }
        onChangeInput={ this.handleChangeVatDetailsFieldInput }
        onChangeChangeRackRate={ this.handleChangeVatDetailsFieldInput }
        onCloseDialog={ this.closeVatDialog }
        onConfirm={ this.handleConfirmVatDialog }
      />
    );
  };

  renderVatNotificationDialog = () => {
    const { showNotificationDialog } = this.state;

    if (!showNotificationDialog) return null;

    return (
      <VatErrorDialog onClose={ this.closeNotificationVatDialog } />
    );
  };

  renderAdditionalInfo = () => {
    const { JsonData: { AdditionalInfo } } = this.state;

    return (
      <div className={ styles.row }>
        <div className={ styles['col-1'] }>
          <Input
            field={ FIELDS.ADDITIONAL_INFO }
            value={ AdditionalInfo }
            label={ TRIPSLABELS.ADDITIONAL_INFO }
            onChange={ (e, field, val) => this.handleChangeAdditionalInfo(field, val) }
          />
        </div>
      </div>
    );
  };

  renderCancellation = () => {
    const {
      JsonData: { Room: { FreeCancellation } },
      CancellationPenalties,
    } = this.state;

    const cancellationPenaltiesList = CancellationPenalties
      ? CancellationPenalties.map((penalty, i) => (
        this.renderCancellationPenalties(penalty.From, penalty.Total, penalty.Base, penalty.Additional, i)
      ))
      : this.renderCancellationPenalties(null, '', '', false);

    return (
      <div className={ styles.row }>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <h4>{ LABELS.CANCELLATION }</h4>
          </div>
          <div className={ styles['col-1-3'] }>
            <h5>{ LABELS.PERIOD }</h5>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <label>{ LABELS.CANCELLATION_DATE }</label>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ DEFAULTTIME }
              value={ dateParseZone(FreeCancellation) }
              onChange={ (value) => this.handleChangeJsonInput(null, FIELDS.ROOMFREECANCELLATION, value) }
              className='componentHook'
            />
            <div className={ styles['add-conditions'] }>
              <FlatButton
                label={ BUTTONS.ADD }
                onClick={ this.handleAddCancellation }
              />
            </div>
          </div>
          <div className={ styles['col-1-2'] }>{ cancellationPenaltiesList }</div>
        </div>
      </div>
    );
  };

  renderCancellationPenalties = (penaltyValue, penaltyTotal, penaltyBase, penaltyAdditional, i) => {
    const {
      JsonData: { Room: { FreeCancellation } },
      validation: {
        Total: total,
        Penalty: { Base: base },
      },
    } = this.state;

    const valueForTrip = dateParseZone(penaltyValue);

    const validTotal = this.getValid(total, i);
    const validBase = this.getValid(base, i);

    const deleteButton = i !== 0
      ? (
        <div className={ styles['col-1-3'] }>
          <div className={ styles['add-conditions'] }>
            <FlatButton
              label={ BUTTONS.REMOVE }
              onClick={ () => this.handleRemoveCancellation(i) }
            />
          </div>
        </div>
      )
      : null;

    return (
      <div className={ styles['cancellation-penalties'] } key={ `cancellation-penalties_${i}` }>
        <div className={ styles['cancellation-penalties-row'] }>
          <div className={ styles['col-1-3'] }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ DEFAULTTIME }
              value={ valueForTrip }
              onChange={ (value) => (
                this.handleChangePenaltyInput(CANCELLATION_PENALTIES.FROM, value, i, FreeCancellation)) }
            />
            <label>С</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <Checkbox
              field={ FIELDS.ADDITIONAL }
              value={ penaltyAdditional || false }
              label={ TRIPSLABELS.ADDITIONAL }
              disabled
            />
          </div>
          { deleteButton }
        </div>
        <div className={ styles['cancellation-penalties-row'] }>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.TOTAL_PENALTY }
              value={ penaltyTotal }
              label={ TRIPSLABELS.SUM }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onChange={ (e, field, val) => this.handleChangePenaltyInput(field, val, i) }
              valid={ validTotal }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.BASE_PENALTY }
              value={ penaltyBase }
              label={ TRIPSLABELS.SUM_BASE }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onChange={ (e, field, val) => this.handleChangePenaltyInput(field, val, i) }
              valid={ validBase }
              onKeyDown={ preventKeyNotNumber }
            />
          </div>
        </div>
      </div>
    );
  };

  renderAnalytics = () => {
    const {
      analytics,
      tripAnalyticsValueIds,
      isTripPaidByPersonalFunds,
    } = this.props;
    const { UserAnalytics: serviceAnalyticsValueIds, validation } = this.state;

    if (isTripPaidByPersonalFunds) return null;

    return (
      <AnalyticsRows
        analytics={ analytics }
        tripAnalyticsValueIds={ tripAnalyticsValueIds }
        serviceAnalyticsValueIds={ serviceAnalyticsValueIds }
        validation={ validation.analytics }
        onSelect={ this.handleSelectAnalytics }
      />
    );
  };

  renderTooltipCalculate = (disabledCountAmount) => {
    const {
      showTooltipCalculate,
      CurrencyData: { Inclusive, MFInCurrency },
    } = this.state;

    if (!showTooltipCalculate || !disabledCountAmount) return null;

    const textTooltip = [];

    if (!Inclusive) {
      textTooltip.push(TRIPSLABELS.ADD_INCLUSIVE);
    }
    if (!MFInCurrency) {
      textTooltip.push(TRIPSLABELS.ADD_MF);
    }

    return <Tooltip>{ textTooltip.join('. ') }</Tooltip>;
  };

  renderCurrency = () => {
    const {
      CurrencyData, JsonData: { ProviderName }, validation,
    } = this.state;

    if (ProviderName !== HOTEL_PROVIDER_VALUE.expedia) return null;

    return (
      <Currency
        currencyInfo={ CurrencyData }
        providerName={ ProviderName }
        onChangeInput={ this.handleChangeCurrency }
        onBlur={ this.validationCurrency }
        valid={ validation }
        disabledInput
      />
    );
  };

  renderCalculateExpediaCurrency = (disabledCountAmount) => {
    const { JsonData: { ProviderName } } = this.state;

    if (ProviderName !== HOTEL_PROVIDER_VALUE.expedia) return null;

    return (
      <div className={ styles.row }>
        <div className='sw-tooltip-wrapper'>
          <div
            onMouseEnter={ () => this.setState({ showTooltipCalculate: true }) }
            onMouseLeave={ () => this.setState({ showTooltipCalculate: false }) }
          >
            <Button
              label={ PRICELABELS.CALCULATE_CURRENCY }
              onClick={ this.handleCalculateCurrency }
              disabled={ disabledCountAmount }
            />
          </div>
          { this.renderTooltipCalculate(disabledCountAmount) }
        </div>
      </div>
    );
  };

  renderNightPrice = () => {
    const { JsonData: { ProviderName, NightsPrice } } = this.state;

    if (ProviderName === HOTEL_PROVIDER_VALUE.expedia) return null;

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FIELDS.NIGHTS_PRICE }
          value={ NightsPrice }
          label={ TRIPSLABELS.NIGHTS_PRICE }
          onChange={ this.handleChangeJsonInput }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
        />
      </div>
    );
  };

  renderReportAmount = () => {
    const { ReportAmount, JsonData: { ProviderName } } = this.state;

    if (ProviderName !== HOTEL_PROVIDER_VALUE.aanda) return null;

    return (
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FIELDS.REPORT_AMOUNT }
          value={ ReportAmount }
          label={ TRIPSLABELS.REPORT_AMOUNT }
          onChange={ (e, field, value) => this.handleChangeReportAmount(field, value) }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
        />
      </div>
    );
  };

  renderSelectProject = () => {
    const { projects, isTripPaidByPersonalFunds } = this.props;
    const {
      validation,
      ProjectId,
      mandatoryProject,
    } = this.state;

    if (isTripPaidByPersonalFunds) return null;

    return (
      <SelectProject
        currentProjectId={ ProjectId }
        projects={ projects }
        mandatoryProject={ mandatoryProject }
        validationProject={ validation.ProjectId }
        onSelect={ this.handleSelectProject }
      />
    );
  };

  render() {
    const {
      PriceDetails,
      Employees,
      validation,
      departments,
      DepartmentId,
      JsonData: {
        CheckinDate,
        CheckoutDate,
        NightsCount,
        ProviderName,
      },
      InternalVat,
      validation: {
        CheckinDate: validationCheckinDate,
        CheckoutDate: validationCheckoutDate,
        Hotel: {
          CheckinTime,
          CheckoutTime,
        },
        Room: { Category },
        EarlyCheckIn,
        LateCheckOut,
      },
      CurrencyData: { Inclusive, MFInCurrency },
      Meal,
      MealLabel,
    } = this.state;

    const employeeId = Employees.map(({ Id }) => Id);

    const data = this.state.JsonData;
    const hotel = data.Hotel;
    const room = data.Room;
    const employee = Employees.map((item) => `${item.Surname} ${item.Name} ${item.Patronymic ? item.Patronymic : ''}`);
    const validCheckinHtml = validationCheckinDate && <span className='error-msg'>{ validationCheckinDate }</span>;
    const validCheckoutHtml = validationCheckoutDate && <span className='error-msg'>{ validationCheckoutDate }</span>;

    const providerNameOptions = this.providerNameOptions;

    const infoHtml = (
      <div className={ styles.info }>
        <span>
          { LABELS.EMPLOYEE }
          :
          { ' ' }
          { employee }
        </span>
        <span>
          { LABELS.RESERVATION_DATE }
          :
          { ' ' }
          { formatDate(data.ReservationDate, FORMATDATE) }
        </span>
        <span>
          { LABELS.GUEST_COUNT }
          :
          { ' ' }
          { data.GuestCount }
        </span>
        <span>
          { LABELS.CHECKIN_DATE }
          :
          { ' ' }
          { formatDate(data.CheckinDate, FORMATDATE) }
        </span>
        <span>
          { LABELS.CHECKOUT_DATE }
          :
          { ' ' }
          { formatDate(data.CheckoutDate, FORMATDATE) }
        </span>
        <span>
          { LABELS.STARS }
          :
          { ' ' }
          { hotel.Stars }
        </span>
        <span>
          { LABELS.NAME }
          :
          { ' ' }
          { hotel.Name }
        </span>
        <span>
          { LABELS.PROVIDER }
          :
          { ' ' }
          { data.ProviderName }
        </span>
        <span>
          { LABELS.ADDRESS }
          :
          { ' ' }
          { hotel.Address }
        </span>
        <span>
          { LABELS.CITY }
          :
          { ' ' }
          { hotel.City }
        </span>
        <span>
          { LABELS.PHONE }
          :
          { ' ' }
          { hotel.Phone }
        </span>
        <span>
          { LABELS.CHECKIN_TIME }
          :
          { ' ' }
          { hotel.CheckinTime }
        </span>
        <span>
          { LABELS.CHECKOUT_TIME }
          :
          { ' ' }
          { hotel.CheckoutTime }
        </span>
        <span>
          { LABELS.ROOM_CATEGORY }
          :
          { ' ' }
          { room.Category }
        </span>
        <span>
          { room.FreeCancellation
            ? `${LABELS.CANCELLATION_DATE}: ${dateUtcFormat(room.FreeCancellation)}`
            : LABELS.FREE_CANCELLATION_NOT_POSSIBLE }
        </span>
        <span>
          { LABELS.BREAKFAST }
          :
          { ' ' }
          { MealLabel }
        </span>
      </div>
    );

    const vatAmount = InternalVat.VatReady && InternalVat.VatInfo.HasVat
      ? InternalVat.VatInfo.VatDetails[0].Amount
      : 0;
    const vatReady = InternalVat.VatReady === null ? false : !InternalVat.VatReady;
    const disabledVatReady = InternalVat.VatReady === null ? false : !InternalVat.VatReady;
    const hasVat = PriceDetails.HasVat ? VATVALUE.WITH : VATVALUE.WITHOUT;
    const isExpedia = ProviderName === HOTEL_PROVIDER_VALUE.expedia;
    const disabledCountAmount = !Inclusive || !MFInCurrency;

    return (
      <div className={ styles.wrap }>
        <div className={ styles.row }>
          <h4>{ LABELS.VOUCHER_INFO }</h4>
          <div className={ styles.row }>
            { infoHtml }
          </div>
          <div className={ styles.row }>
            { this.renderSelectProject() }
            <SelectDepartment
              departments={ departments }
              onSelect={ this.handleSelectDepartment }
              currentDepartmentId={ DepartmentId }
              currentEmployeeId={ employeeId[0] }
            />
          </div>
          { this.renderAnalytics() }
          <div className={ styles.row }>
            <div className={ styles['col-1'] }>
              <Input
                field={ FIELDS.RESERVATIONNUMBER }
                value={ data.ReservationNumber }
                label={ TRIPSLABELS.RESERVATIONNUMBER }
                onChange={ this.handleChangeJsonInput }
                onBlur={ this.validationInput }
                valid={ validation.ReservationNumber }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1'] }>
              <Input
                field={ FIELDS.HOTEL_ORDER_ID }
                value={ data.HotelOrderId }
                label={ TRIPSLABELS.HOTEL_ORDER_ID }
                onChange={ this.handleChangeJsonInput }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1-3'] }>
              <DatePicker
                dateFormat={ DEFAULTDATE }
                timeFormat=''
                locale={ LOCALERU }
                value={ CheckinDate }
                onChange={ (value) => this.handleChangeJsonInput(null, FIELDS.CHECKIN_DATE, value) }
                onBlur={ () => this.validateDate(FIELDS.CHECKIN_DATE) }
                className={ `componentHook ${validationCheckinDate ? 'no-valid' : ''}` }
              />
              <label>{ LABELS.CHECKIN_DATE }</label>
              { validCheckinHtml }
            </div>
            <div className={ styles['col-1-3'] }>
              <DatePicker
                dateFormat={ DEFAULTDATE }
                timeFormat=''
                locale={ LOCALERU }
                value={ CheckoutDate }
                onChange={ (value) => this.handleChangeJsonInput(null, FIELDS.CHECKOUT_DATE, value) }
                onBlur={ () => this.validateDate(FIELDS.CHECKOUT_DATE) }
                className={ `componentHook ${validationCheckoutDate ? 'no-valid' : ''}` }
              />
              <label>{ LABELS.CHECKOUT_DATE }</label>
              { validCheckoutHtml }
            </div>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ FIELDS.NIGHTS_COUNT }
                value={ NightsCount }
                label={ TRIPSLABELS.NIGHTS_COUNT }
                onChange={ this.handleChangeJsonInput }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ FIELDS.HOTELCHECKINTIME }
                value={ hotel.CheckinTime }
                label={ TRIPSLABELS.HOTELCHECKINTIME }
                onChange={ this.handleChangeJsonInput }
                onBlur={ this.validationInput }
                valid={ CheckinTime }
              />
            </div>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ FIELDS.HOTELCHECKOUTTIME }
                value={ hotel.CheckoutTime }
                label={ TRIPSLABELS.HOTELCHECKOUTTIME }
                onChange={ this.handleChangeJsonInput }
                onBlur={ this.validationInput }
                valid={ CheckoutTime }
              />
            </div>
            <div className={ styles['col-1-3'] }>
              <div className={ styles.select }>
                <div className={ styles.wrap }>
                  <label>{ LABELS.PROVIDER }</label>
                  <select
                    value={ data.ProviderName }
                    onChange={ (e) => this.handleChangeJsonInput(null, FIELDS.PROVIDERNAME, e.target.value) }
                  >
                    { providerNameOptions.map(({ value, label }, key) => (
                      <option key={ key } value={ value } hidden={ value === HOTEL_PROVIDER_VALUE.booking }>
                        { label }
                      </option>)) }
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1-4'] }>
              <Checkbox
                field={ FIELDS.CUSTOMCHECKINDATE }
                value={ data.CustomCheckInDate || false }
                label={ TRIPSLABELS.CUSTOMCHECKINDATE }
                onChange={ this.handleChangeECLCCheckbox }
              />
            </div>
            <div className={ styles['col-1-4'] }>
              <Input
                field={ FIELDS.EARLYCHECKIN }
                value={ PriceDetails.EarlyCheckIn }
                label={ TRIPSLABELS.EARLYCHECKIN }
                onChange={ this.handleChangePriceDetailsInput }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onKeyDown={ preventKeyNotNumber }
                valid={ EarlyCheckIn }
              />
            </div>
            <div className={ styles['col-1-4'] }>
              <Checkbox
                field={ FIELDS.CUSTOMCHECKOUTDATE }
                value={ data.CustomCheckOutDate || false }
                label={ TRIPSLABELS.CUSTOMCHECKOUTDATE }
                onChange={ this.handleChangeECLCCheckbox }
              />
            </div>
            <div className={ styles['col-1-4'] }>
              <Input
                field={ FIELDS.LATECHECKOUT }
                value={ PriceDetails.LateCheckOut }
                label={ TRIPSLABELS.LATECHECKOUT }
                onChange={ this.handleChangePriceDetailsInput }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onKeyDown={ preventKeyNotNumber }
                valid={ LateCheckOut }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1-4'] }>
              <Input
                field={ FIELDS.ROOMCATEGORY }
                value={ room.Category }
                label={ TRIPSLABELS.ROOMCATEGORY }
                onChange={ this.handleChangeJsonInput }
                onBlur={ this.validationInput }
                valid={ Category }
              />
            </div>
            <MealFields
              meal={ Meal }
              onChangeStateInput={ (e, field, value) => this.handleChangeState(field, value) }
            />
          </div>
          { this.renderAdditionalInfo() }
          { this.renderCancellation() }
          <div className={ `${styles.row} ${styles.panel}` }>
            { this.renderNightPrice() }
            <div className={ styles['col-1-4'] }>
              <Input
                field={ FIELDS.NIGHTS_COUNT }
                value={ NightsCount }
                label={ TRIPSLABELS.NIGHTS_COUNT }
                onChange={ this.handleChangeJsonInput }
                disabled
              />
            </div>
          </div>
          <div className={ styles.row }>
            { this.renderReportAmount() }
          </div>
          <div className={ `${styles.row} ${styles.panel}` }>
            <div className={ styles['col-1-4'] }>
              <Input
                field={ PRICEFIELDSSHORT.BASE }
                value={ PriceDetails.Base }
                label={ PRICELABELS.BASEPRICE }
                onChange={ this.handlerChangePriceInput }
                onBlur={ this.validationPrice }
                valid={ validation.Base }
                disabled={ isExpedia }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onKeyDown={ preventKeyNotNumber }
              />
            </div>
            <div className={ styles['col-1-4'] }>
              <Input
                field={ PRICEFIELDSSHORT.COMMISSION }
                value={ PriceDetails.Commission }
                label={ PRICELABELS.TAXPENALTIES }
                onChange={ this.handlerChangePriceInput }
                onBlur={ this.validationPrice }
                valid={ validation.Commission }
                disabled={ isExpedia }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onKeyDown={ preventKeyNotNumber }
              />
            </div>
            <div className={ `${styles['col-1-2']}` }>
              <VatBlock
                isEdit
                vatReady={ vatReady }
                vatAmount={ vatAmount }
                providerName={ data.ProviderName }
                internalVat={ InternalVat }
                base={ PriceDetails.Base }
                hasVat={ hasVat }
                disabledVatReady={ disabledVatReady }
                validation={ validation }
                onChangeVatReady={ this.handleChangeVatReady }
                onAddVat={ this.handleAddVat }
              />
            </div>
          </div>
          { this.renderCurrency() }
          { this.renderCalculateExpediaCurrency(disabledCountAmount) }
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label={ TRIPSLABELS.CONFIRM }
            />
          </div>
        </div>
        { this.renderVatDialog() }
        { this.renderVatNotificationDialog() }
      </div>
    );
  }
}

export default PartHotel;
